import styled from 'styled-components';
import { Button as BootstrapButton } from 'react-bootstrap';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1120px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 30px;

  h1 {
    text-align: center;
    font-size: 30px;
    margin: 30px;
  }

  h6 {
    text-align: center;
    margin-bottom: 30px;
  }
`;

export const TextBox = styled.div`
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 30px;
`;

export const Text = styled.span`
  color: #003869;
  position: relative;

  &:hover {
    background-color: #003869;
    color: #fff;
    cursor: default;
  }

  &:hover span {
    visibility: visible;
  }
`;

export const NormalText = styled.span`
  color: #003869;
  position: relative;

  &:hover {
    cursor: default;
  }
`;

export const Tooltip = styled.span`
  display: flex;
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 2px 5px;
  border-radius: 6px;
  width: auto;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const Button = styled(BootstrapButton)``;

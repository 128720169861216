import { createGlobalStyle } from 'styled-components';
import { shade } from 'polished';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #003869;
    color: #fff;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'Roboto', serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  .btn-primary {
    background-color: #fff;
    color: #003869;
    border-color: #fff;
    width: 250px;

    &:hover,
    &:active,
    &:focus {
      background-color: ${shade(0.3, '#fff')};
      color: #003869;
      border-color: ${shade(0.3, '#fff')};
    }
  }

  .btn-primary.disabled {
    background-color: ${shade(0.3, '#fff')};
    color: #003869;
    border-color: ${shade(0.3, '#fff')};
    width: 250px;
  }
`;

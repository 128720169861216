import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import { Home } from '../pages/Home';
import { History } from '../pages/History';
import { SpecificStory } from '../pages/SpecificStory';
import { NotFound } from '../pages/NotFound';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/atual" component={Home} />
    <Route path="/historico/:storyId" component={SpecificStory} />
    <Route path="/historico" component={History} />
    <Route path="*" component={NotFound} />
  </Switch>
);

export default Routes;

import styled from 'styled-components';
import { Card as BootstrapCard, Button as BootstrapButton } from 'react-bootstrap';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1120px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 30px;

  h1 {
    text-align: center;
    font-size: 30px;
    margin: 30px;
  }
`;

export const CardsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 11px;
`;

export const Card = styled(BootstrapCard)`
  margin-left: 19px;
  margin-bottom: 19px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export const Button = styled(BootstrapButton)``;

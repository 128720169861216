import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useToast } from '../../hooks/toast';

import api from '../../services/api';
import Loader from '../../components/Loader';

import { Container, Content, TextBox, Text, NormalText, ButtonContainer, Button } from './styles';

type ViewerText = {
  order: number;
  username: string;
  text: string;
}

interface IParams {
  storyId: string;
}

export const SpecificStory: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { storyId } = useParams<IParams>();
  const [description, setDescription] = useState<string | null>();
  const [viewersText, setViewersText] = useState<ViewerText[]>();
  const [previouStoryId, setPreviouStoryId] = useState<string | null>();
  const [nextStoryId, setNextStoryId] = useState<string | null>();

  useEffect(() => {
    async function loadStory(): Promise<void> {
      try {
        const { data } = await api.get(`/story/${storyId}`);

        setPreviouStoryId(data.previouStoryId);
        setNextStoryId(data.nextStoryId);
        setDescription(data.story.description);
        setViewersText(data.story.texts);
      } catch (error) {
        history.push('/historico');

        addToast({
          type: 'error',
          title: 'Infelizmente não conseguimos encontrar esta história',
        });
      }
    }

    loadStory();
  }, [storyId, addToast, history])

  return (
    <Container>
      {!viewersText ? (
        <Loader justIcon />
      ) : (
        <Content>
          <h1>História do chat</h1>

          {description && (
            <h6>{description}</h6>
          )}

          <TextBox>
            {viewersText.length === 0 && (
              <NormalText>História não iniciada...</NormalText>
            )}

            {viewersText.map((viewerText) => (
              <OverlayTrigger
                key={viewerText.order}
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {`por `}<strong>{viewerText.username}</strong>
                  </Tooltip>
                }
              >
                <Text>{`${viewerText.text.trim()} `}</Text>
              </OverlayTrigger>
            ))}
          </TextBox>

          <ButtonContainer>
            <Button
              disabled={!previouStoryId}
              variant="primary"
              href={`/historico/${previouStoryId}`}
            >
              História anterior
            </Button>

            <Button
              variant="primary"
              href="/historico"
            >
              Ver todas as histórias
            </Button>

            <Button
              disabled={!nextStoryId}
              variant="primary"
              href={`/historico/${nextStoryId}`}
            >
              Próxima história
            </Button>
          </ButtonContainer>
        </Content>
      )}
    </Container>
  );
};

import React, { useState, useEffect } from 'react';

import api from '../../services/api';
import Loader from '../../components/Loader';

import { Container, Content, CardsList, Card, ButtonContainer, Button } from './styles';

type Text = {
  id: string;
  title: string;
  description: string;
  preview: string;
}

export const History: React.FC = () => {
  const [texts, setTexts] = useState<Text[]>();

  useEffect(() => {
    async function loadStory(): Promise<void> {
      const { data } = await api.get(`/story/finished`);

      setTexts(data);
    }

    loadStory();
  }, []);

  return (
    <Container>
      {!texts ? (
        <Loader justIcon />
      ) : (
        <Content>
          <h1>Histórias anteriores</h1>

          <CardsList>
            {texts.map(text => (
              <Card
                key={text.id}
                style={{ width: '16rem', color: "#003869" }}
              >
                <Card.Link href={`/historico/${text.id}`} style={{ textDecoration: 'none', color: "#003869" }} >
                  <Card.Body>
                    <Card.Title>{text.title || 'Sem nome'}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{text.description}</Card.Subtitle>
                    <Card.Text>{text.preview}</Card.Text>
                  </Card.Body>
                </Card.Link>
              </Card>
            ))}
          </CardsList>

          <ButtonContainer>
            <Button
              variant="primary"
              href="/atual"
            >
              Ver história atual
            </Button>
          </ButtonContainer>
        </Content>
      )}
    </Container>
  );
};

import React from 'react';
import { VscLoading } from 'react-icons/vsc';

import { Container, Content } from './styles';

interface ILoaderProps {
  justIcon?: boolean;
}

const Loader: React.FC<ILoaderProps> = ({ justIcon = false }) => (
  <Container>
    <Content>
      <VscLoading size={50} />
      {!justIcon && <h4>Aguarde enquanto carregamos as informações</h4>}
    </Content>
  </Container>
);

export default Loader;

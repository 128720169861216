import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import api from '../../services/api';
import Loader from '../../components/Loader';

import { Container, Content, TextQuantityContainer, TextBox, Text, NormalText } from './styles';
// import { Container, Content, TextQuantityContainer, TextBox, Text, NormalText, ButtonContainer, Button } from './styles';

type ViewerText = {
  order: number;
  username: string;
  text: string;
}

export const Home: React.FC = () => {
  const [viewersText, setViewersText] = useState<ViewerText[]>();
  const [textsLimit, setTextsLimit] = useState<number>();

  useEffect(() => {
    async function loadStory(): Promise<void> {
      const { data } = await api.get(`/story/current`);

      setViewersText(data.texts);
      setTextsLimit(data.textsLimit);
    }

    loadStory();
  }, []);

  return (
    <Container>
      {!viewersText ? (
        <Loader justIcon />
      ) : (
        <Content>
          <h1>História do chat</h1>

          <h6>
            Essa história está sendo escrita pelo chat, através do resgate de recompensa do canal na Twitch. Para participar basta resgatar a recompensa e colocar a continuação da história no campo de texto. O texto segue as regras do chat da live, portanto, se o que você escrever quebrar as regras ou o bom senso, sua parte será excluída e você sofrerá as consequências.
          </h6>

          {textsLimit && (
            <TextQuantityContainer>
              <h6>
                Total de partes já escritas: {viewersText.length}/{textsLimit}
              </h6>
            </TextQuantityContainer>
          )}

          <TextBox>
            {viewersText.length === 0 && (
              <NormalText>História não iniciada...</NormalText>
            )}

            {viewersText.map((viewerText) => (
              <OverlayTrigger
                key={viewerText.order}
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {`por `}<strong>{viewerText.username}</strong>
                  </Tooltip>
                }
              >
                <Text>{`${viewerText.text.trim()} `}</Text>
              </OverlayTrigger>
            ))}
          </TextBox>

          {/* <ButtonContainer>
            <Button
              variant="primary"
              href="/historico"
            >
              Ver histórias anteriores
            </Button>
          </ButtonContainer> */}
        </Content>
      )}
    </Container>
  );
};
